
/*!
 * Reset
 */ 
   article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
   audio, canvas, video { display: inline-block; }
   audio:not([controls]) { display: none; }
   [hidden] { display: none; }
   
   
   /* -------------------------------GENERAL-------------------------------------------*/
   
   
  //  html { font-size: 100%; //-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-weight:normal;}
  //  body { margin: 0; font-size: 13px; line-height: 1.231; padding:0 }
  //  body, button, input, select, textarea {font-weight:normal;}
   
   
   * {
   margin:0;
   padding:0;
   box-sizing: border-box;
   border: none;
   }

   *:focus, *:active{
    outline: 0;
}

button, .btn{

  &:not(:disabled):not(.disabled){
       padding:0 5px;
    }

  i.im{
      padding: 0;
      font-size: .9em;
      line-height: 1.5em;
  }
  box-shadow: none !important;
}

.markdown{
    white-space: pre-wrap; 
    word-break: break-word; 
}

/*RESET BORDER RADIUS */

button,
.form-control, 
.btn,
.custom-checkbox .custom-control-label::before,
.custom-select,
.alert,
.progress, 
.card,
.card-header,
.breadcrumb,
.list-group-item,
.badge,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.input-group>.custom-file:not(:last-child) .custom-file-label
{
  border-radius: 0 !important;
  outline: none !important;
 }
@keyframes mask-play {
    to {
        mask-position: 0% 0;
    }

    from {
        mask-position: 100% 0;
    }
}

@keyframes image-disort {
    @for $i from 0 through 5 {
        $percent: 0% + $i * 20;
        $random: random(50) - 25 + px;

        #{$percent} {
            background-position-y: $random;
        }
    }
}

@keyframes clip-text {
    from {
        clip-path: inset(0% 100% 40% 0%);
    }

    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes clip-image {
    from {
        clip-path: inset(0% 0% 100% 0%);
    }

    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes text-revealer {
    0% {
        opacity: 1;
        transform-origin: 50% 50%;
    }

    60% {}

    0%, 60%, 100% {
        mask-image: url("./assets/Logo/logo-proyecto-magma.svg");
    }

    0% {
        transform: scaleX(1);
    }

    60% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(1);
    }

    100% {
        opacity: 1;
        mask-size: 2500%;
    }
}

@keyframes text-revealer-reverse {
    0%, 50% {
        transform-origin: 0 50%;
    }

    60%, 100% {
        transform-origin: 100% 100%;
    }

    60% {
        transform: scaleX(2) skewX(43deg);
    }

    100% {
        transform: scaleX(1);
    }
}

@keyframes jump {
    0% {
        transform: translate3d(0, 200%, 0) scale3d(1, 1, 1);
    }

    40% {
        transform: translate3d(0, 30%, 0) scale3d(0.7, 1.1, 1);
    }

    100% {
        transform: translate3d(0, 0%, 0) scale3d(1.1, 0.95, 1);
    }
}

@keyframes jump2 {
    0% {
        transform: translate3d(100% 0, 0) scale3d(1, 1, 1);
    }

    40% {
        transform: translate3d(30%, 0, 0) scale3d(0.9, 1.1, 1);
    }

    100% {
        transform: translate3d(0, 0%, 0) scale3d(1.1, 0.99, 1);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

//LUNA

@keyframes eclipse-full {
    0% {
        left: -80px;
    }

    8.33%, 25% {
        left: 0px;
    }

    33.33%, 50% {
        left: 80px;
    }

    58.33%, 75% {
        left: 0px;
    }

    83.33%, 100% {
        left: -80px;
    }

    0% {
        background: cssvar(light); //cssvar(primary-cta-active);
    }
    25% {
        background: cssvar(light); //cssvar(secondary-cta-active);
    }

    50% {
        background: cssvar(light); //cssvar(primary-cta-active);
    }

    75% {
        background: cssvar(light); //cssvar(secondary-cta-active);
    }

    100% {
        background: cssvar(light); //cssvar(primary-cta-active);
    }
}



@keyframes eclipse-full-background {
    0% {
        background: cssvar(primary-icon);//cssvar(main-background);
    }

    8.33%, 25% {
        background: cssvar(accent);//cssvar(primary-cta-active);
    }

    33.33%, 50% {
        background: cssvar(primary-icon);
    }

    58.33%, 75% {
        background: cssvar(accent);//cssvar(primary-cta-active);
    }

    83.33%, 100% {
        background: cssvar(primary-icon);
    }
}

@keyframes eclipse {
    0% {
        left: 20%;
    }

    33.33%, 50% {
        left: 5%;
    }

    83.33%, 100% {
        left: -20%;
    }
}

@mixin gradient-animation($property, $speed, $times, $color) {
    $name: #{$property}-#{str-replace(#{$color}, "#", "")};

    animation: gradient-#{$name} #{$speed}s linear $times;

    $backGradient: linear-gradient(-45deg, transparent, $color, transparent, $color);
    $backsize: 400% 400%;

    @keyframes gradient-#{$name} {
        0% {
            background: $backGradient;
            background-size: $backsize;
            opacity: 1;
        }

        1% {
            background: $backGradient;
            background-position: 0% 0%;
        }

        50% {
            background: $backGradient;
            background-size: $backsize;
            background-position: 50% 50%;
        }

        100% {
            background: $backGradient;
            background-size: $backsize;
            background-position: 100% 100%;
            opacity: 0;
        }
    }
}


@mixin color-change-animation($property, $speed, $times) {
    animation: color-change-#{$property} #{$speed}s linear $times;

    @keyframes color-change-#{$property} {
        0% {
            #{$property}: cssvar(primary-cta-active);
            opacity: 1;
        }

        25% {
            #{$property}: cssvar(main-background);
        }

        50% {
            #{$property}: cssvar(primary-text);
        }

        75% {
            #{$property}: cssvar(primary-cta-active);
        }

        100% {
            #{$property}: cssvar(primary-text);
            opacity: 0;
        }
    }
}

@mixin mask-magma-animation($name, $min, $max, $speed, $times) {
    mask-image: url("./assets/Logo/logo-proyecto-magma.svg");
    mask-size: auto;
    opacity: 0;
    animation: revealer-#{$name} $speed cubic-bezier(0.9, 0.04, 0.22, 1) $times;

    @keyframes revealer-#{$name} {
        0% {
            opacity: 1;
            transform-origin: 50% 50%;
            transform: scale(0.5);
        }

        0%, 60%, 100% {
            mask-image: url("./assets/Logo/logo-proyecto-magma.svg");
        }

        60% {
            transform: scale(0.6);
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: scale(0.5);
        }
    }
}

@mixin mask-linear-animation($name, $min, $max, $speed, $times) {
    animation: background-#{$name} $speed linear $times;
    object-position: 1em;

    img {
        animation: linear-#{$name} $speed linear $times;
        mask: url("./assets/Logo/logo-proyecto-magma.svg"), repeating-linear-gradient(50deg, red, red 1em, transparent 0, transparent 2em), linear-gradient(black, transparent);
        mask-size: cover;
        object-position: -1em;
    }

    @keyframes linear-#{$name} {
        0% {
            object-position: 0em;
        }

        0%, 60%, 100% {}

        25% {
            object-position: -.5em;
        }

        55% {
            object-position: 0em;
        }

        60% {
            object-position: -.3em;
        }

        100% {
            object-position: -.2em;
        }
    }

    @keyframes background-#{$name} {
        @for $i from 0 through 5 {
            $percent: 0% + $i * 20;
            $random: random(10) - 5 + px;

            #{$percent} {
                background-position-y: $random;
            }
        }
    }
}





@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}

@keyframes marquee-reverse {
  0%   { transform: translate(-100%, 0); }
  100% { transform: translate(200%, 0); }
}

//SHARE BUTTONS

.share-buttons{
   a{
        display: inline-flex;
        margin-right: 10px;
   }
}

.social-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    margin-left: -15px;
    font-size: 1.2em;

    .btn-simple:not(:disabled):not(.disabled){
        transition: none;
        &:after, &:before{
            transition: none;
        }
    }

    .open-share{      
        position: absolute;
        top: 0px;
        z-index: 1;
        left: 0;
        margin: 1px 0 0 6px;
        font-size: 1em;
        background: cssvar(primary-cta);
    }
    .share-buttons{
        display: flex;
        align-items: baseline;
        margin-bottom: 3px;
        position: relative;
        background:transparent;
        max-width: 100%;
        justify-content: space-evenly;
        padding-bottom: 2px;
        
        .im{
            font-size:1.5em;
        }

        &:after{
            content:'';
            @include mask($buttonEnd);
            right: -1.7em;
            width: 1.7em;
            height: 115%;
            left: initial;
            transform: scaleX(1);
            background: cssvar(primary-cta);
            top: -2px;
            position: absolute;
        }

        @include desktop{
            max-width: 155px;
            justify-content: space-evenly;
            .im{
                font-size:1em;
            }
        }
        
        .share-button{
            margin:.2em 8px;
            height:20px;
            font-size: 1.2em;
            color: cssvar(primary-cta);

            i{
                font-size: 1em;
            }
        
            &:hover {
                color: cssvar(primary-cta-hover);
            }
        }

        transform: translate3d(40px,0%,0);

        &.active, &:hover{
            .share-button{
               animation: jump2 .2s cubic-bezier(0.0, 0.0, 0.2, 1);
                &:nth-child(2){
                    animation: jump2 .4s cubic-bezier(0.0, 0.0, 0.2, 1);
                }
                &:nth-child(3){
                    animation: jump2  .6s cubic-bezier(0.0, 0.0, 0.2, 1);
                }
                &:nth-child(4){
                    animation: jump2 .4s cubic-bezier(0.0, 0.0, 0.2, 1);
                }
            }
        }        
    }
}

.notas-container {
    .notas-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 20px;
    }
}

.nota-wrapper {
    .container-notas {
        position: relative;

        .row-categorias,
        .row-autores {
            @include desktop {
                padding: 0 #{$margenes + 20px};
            }
        }

        $padding: 5px;
        $anchoBorde: 8px;

        .row-categorias {
            position: relative;
            padding-top: 20px;

            &:before {
                content: '';
                position: absolute;
                width: calc(100% - #{$anchoBorde * 2} - #{$padding * 2});
                height: 10px;
                border: 2px solid cssvar(primary-cta);
                border-left-width: $anchoBorde;
                border-right-width: $anchoBorde;
                top: 15px;
                left: calc(#{$anchoBorde} + #{$padding});

                @include desktop {
                    width: calc(100% - #{$anchoBorde * 2} - #{$padding * 2} -  #{$margenes * 2});
                    left: calc(#{$anchoBorde} + #{$padding} +  #{$margenes});
                }
            }

            .nota-categorias {
                text-align: left;
                padding-right: 0;
                margin-bottom: 20px;

                .categorias-container {
                    padding: 0;

                    .badge-primary:before, .badge-primary:after {
                        mask: none;                   
                    }
                }
            }
        }

        .bajada {
            color: cssvar(secondary-text);
            position: relative;

            @include desktop {
                padding: 20px #{$margenes + 20px};
            }


            .fecha-nota {
                color: cssvar(headlines);
                font-weight: bold;
                font-size: 1.2em;
                padding: 0;
            }

            .share-buttons {
                max-width: #{$margenes * 1.5};

                a {
                    margin-top: 5px;
                }
            }

            .markdown {
                color: cssvar(secondary-text);
                font-size: 1.3em;

                p, a {
                    color: cssvar(secondary-text);
                }
            }
        }

        .autores-nota {
            text-align: right;
            color: cssvar(secondary-text);
        }

        .cuerpo-nota {
            font-size: 1.2em;

            h3 {
                @include fuente-magma-titulos-notas(1.2em);
            }

            .cuerpo-containers {
                .card.default {
                    p.markdown.text {
                        display: none;
                    }
                }

                .con-caption {
                    position: relative;

                    .caption {
                        display: none;
                        position: absolute;
                        width: 100%;
                        height: 20px;
                        background: cssvar(main-background);
                        color: cssvar(primary-text);
                        text-align: right;
                        padding-right: 5px;
                        line-height: 20px;
                        padding-bottom: 0;
                        margin: 0;
                        bottom: 0;
                        font-size: .8em;

                        &.caption-visible {
                            display: block;
                        }
                    }
                }

                &.galeria-container {
                    .galeria-item {
                        margin-bottom: 20px;
                        text-align: center;

                        img {
                            min-height: 250px;
                            object-fit: cover;
                        }
                    }

                    &:not(.simple).full {
                        .titulo-seccion-nota {
                            padding: 0;

                            @include desktop {
                                padding: 20px #{$margenes};
                            }
                        }

                        .galeria-item {
                            margin-bottom: 0px;
                        }
                    }
                }

                &.simple.full {
                    a.btn {
                        margin-left: 35px;
                    }
                }

                @include desktop {
                    &.full:not(.galeria-container):not(.texto-container) {
                        padding: 20px #{$margenes};
                    }

                    &.odd {
                        padding-left: #{$margenes + 20px};
                    }

                    &.even {
                        padding-right: #{$margenes + 20px};
                    }
                }
            }
        }
    }

    .container-notas-other {
        @include desktop {
            padding: 20px #{$margenes};
        }

        .comentario {
            margin-bottom: 20px;
            border-bottom: 1px solid cssvar(primary-cta);

            .autor-comentario {
                text-align: right;
                font-style: italic;
                padding-bottom: 10px;
            }
        }

        #comment-form {
            .inputs.row {
                flex-direction: column;

                label {
                    display: none;
                }

                .form-group {
                    text-align: right;
                }
            }
        }

        .saving-comment {
            position: relative;

            span {
                border: 5px solid cssvar(primary-cta-active);
                border-top: 5px solid cssvar(primary-cta);
                border-radius: 50%;
                width: 25px;
                height: 25px;
                animation: spin 2s linear infinite;
                position: absolute;
                right: 0;
                top: 0;
            }

            p {
                font-style: italic;
                color: cssvar(secondary-text);
            }
        }
    }
}


//TODO REVISAR COLORES

df-messenger {
    --df-messenger-bot-message: #f7e3d2;
    --df-messenger-button-titlebar-color: #ff0000;
    --df-messenger-chat-background-color: #f7e3d2;
    --df-messenger-font-color:  #160f0f;
    --df-messenger-send-icon: #ff0000;
    --df-messenger-user-message: #ff0000;
    --df-messenger-chip-border-color: #ff0000;
}

.dialogflow{
    white-space: normal;
}
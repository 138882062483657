form {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    transition: all .2s ease-in;
    height: auto;
    margin-bottom: 0;

    h4 {
        padding-top: #{$margenes - 20px};
        font-size: 1.2em;
        color: cssvar(primary-text);
    }

    &.form-container,
    .form-group {
        width: 100%;
    }

    .form-group {
        flex: 1 1 0px;
        margin: 10px 0 0 0;
        padding: 0px 50px 0 0;
        width: 100%;
        justify-content: space-between;
    }

    .form-control,
    input[type='text'],
    input[type='email'] {
        border: none;
        background-color: transparent !important;
        border-bottom: 2px dotted cssvar(primary-cta);
        color: cssvar(primary-text); 
        border-color: cssvar(primary-cta);
        padding: .375rem 0;
        box-shadow: none !important;

        &:valid {
            border-bottom: 2px solid cssvar(primary-cta);
        }

        &:focus{
            color: cssvar(primary-text);  
        }
    }

    input::placeholder {
        color: cssvar(primary-text);
        opacity: .6;
    }

    select {
        display: block;
        font-size: 16px;
        font-family: sans-serif;
        font-weight: 700;
        color: cssvar(primary-cta-active);
        line-height: 1.3;
        padding: .6em 1.4em .5em .8em;
        width: 100%;
        max-width: 100%; /* useful when width is set to anything other than 100% */
        box-sizing: border-box;
        margin: 0;
        border: 2px solid cssvar(primary-cta-active);
        box-shadow: none;
        border-radius: 0;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;

        // $startData: "data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22#{url-friendly-colour($colorBotones)}";
        // background-image: url('#{$startData}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        // linear-gradient(to bottom, cssvar(primary-cta-active) 0%,cssvar(primary-cta) 100%);
        // background-repeat: no-repeat, repeat;
        // background-position: right .7em top 50%, 0 0;
        // // /* icon size, then gradient
        // background-size: .65em auto, 100%;

        &:hover, &:focus {
            border-color: cssvar(primary-cta-active);

            // $startData: "data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22#{url-friendly-colour($colorBotonesActivos)}";
            // background-image: url('#{$startData}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
            // linear-gradient(to bottom,$colorOpuesto 0%,$colorOpuesto 100%);
            outline: none;
        }

        /* Set options to normal weight */
        option {
            font-weight: normal;
        }

        /* Disabled styles */
        //&:disabled, &[aria-disabled=true] {
            // color: graytext;
            // background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
            // linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
       
        //}

        &:disabled:hover, &[aria-disabled=true] {
            border-color: #aaa;
        }
    }

    .radio, .checkbox {
        display: flex;
        grid-template-columns: min-content auto;
        grid-gap: 0.5em;
        font-size: 1.2em;
        color: cssvar(primary-cta-active);

        &:focus-within {
            .radio__label {
                opacity: 1;
            }
        }
    }

    .radio__label, .checkbox__label {
        line-height: 1;
        transition: 180ms all ease-in-out;
        opacity: 0.8;
    }

    .radio__input, .checkbox__input {
        display: flex;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

    .radio-before,
    .checkbox-before {
        .radio__control,
        .checkbox__control {
            display: grid;
            place-items: center;
        }

        input + .radio__control::before,
        input + .checkbox__control::before {
            content: "";
            width: 0.5em;
            height: 0.5em;
            box-shadow: inset 0.5em 0.5em currentColor;
            border-radius: 50%;
            transition: 180ms transform ease-in-out;
            transform: scale(0);
        }

        input + .checkbox__control::before {
            border-radius: initial;
        }

        input:checked + .radio__control::before, input:checked + .checkbox__control::before {
            transform: scale(1);
            color: cssvar(primary-cta);
        }
    }

    .radio__control,
    .checkbox__control {
        display: block;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        border: 0.1em solid currentColor;

        &.checkbox__control {
            border-radius: initial;
        }
    }

    .radio-containers {
        display: flex;
        justify-content: space-evenly;
        margin: 20px auto;

        .radio {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    textarea.form-control,
    textarea {
        background: none;
        width: 100%;
        padding: 10px;
        color: cssvar(primary-text);
        border: 2px solid cssvar(primary-cta);

        &:focus {
            border: 2px solid cssvar(primary-cta);
            color: cssvar(primary-text);
        }
    }
}

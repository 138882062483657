.authPage {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 50px 0;
  background: #FAFAFB;
  //-webkit-font-smoothing: antialiased;
  min-height: calc(100vh - 183px);

  .wrapper {
    text-align: center;
    /* background-image: url('../../images/background_empty.svg'); */
    background-position-x: center;
    font-size: 14px;

    .headerContainer {
      line-height: 36px;
      font-size: 24px;
      font-weight: 600;

      img{
        width: 100px;
      }
    }

    .formContainer {
      min-height: 200px;
      width: 416px;
      margin: 14px auto;
      margin-bottom: 0;
      padding: 33px 15px 15px 15px;
      border-radius: 2px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px 0 #E3E9F3;
      
      .loginButton {
        margin-top: -6px;
        padding-right: 0;
        text-align: right;
      }
      
      .loginButton, button {
        margin-right: 16px;
      }
      
      .buttonContainer {
        padding-top: 3px;
      }
      
    }
    
    .linkContainer, a {
      color: #262931;
      font-size: 13px;
    }
    
    .logoContainer {
      position: absolute;
      left: 30px; bottom: 30px;
    }
    
    /* .logoContainer, img {
      height: 34px;
    } */
    
    .separator {
      width: 100%;
      height: 2px;
      margin: 10px 0;
      background-color: rgba(14, 22, 34, 0.04);
    }
    

  }

}



// .errorsContainer {
//   margin-top: -21px;
//   margin-bottom: 18px;
//   color: #ff203c;
// }



/* .headerContainer, img {
  margin-top: 1px;
  height: 40px;
} */


.detalle-page-wrapper {
    .element-wrapper {
        min-height: calc(100vh - #{$altoMenu * 2});

        @include desktop {
            padding: 20px #{$margenes + 20px};
        }


        img {
            max-width: 100%;
        }

        h3.titulo {
            @include fuente-magma-titulos-notas(1.2em);

            text-transform: initial;
        }

        section {
            margin-bottom: $margenes;
        }

        &.container-notas {
            padding-top: 20px;
        }

        &.container-cocreadores, &.container-edicion {
            .image-container {
                order: 2;
                margin: 10px auto;
            }

            .titulo-container {
                order: 1;
            }

            .texto-container {
                order: 3;
                padding: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .markdown {
                    font-size: 1.3em;
                    padding: 10px 0;
                }

                h5.fecha, p.fecha {
                    text-align: right;
                    margin-top: 20px;
                }

                .categorias-container {
                    padding-left: 10px;
                }
            }

            @include desktop {
                .image-container,
                .titulo-container,
                .texto-container {
                    order: initial;
                }
            }


            @include tablet {
                .image-container,
                .titulo-container,
                .texto-container {
                    order: initial;
                }
            }
        }
    }
}
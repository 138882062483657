.homepage-container.empty{
    main{
      font-size: 1.2em;
      padding-bottom:50px;
      height: 90vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
    
      h3{
        @include fuente-magma-titulos-notas(4em);
        position: absolute;
        z-index: 99999;
        top: 60vh;
      }
    }
}
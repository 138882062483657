.texto-container{
    align-items: flex-start;
    padding-top:20px;

    h3{
        @include fuente-magma-titulos-notas(1.2em);
    }

    @include desktop{
        &.full{
            padding: 20px 20%;
        }

        &.odd{
            padding-left: #{$margenes + 20px};
          }

        &.even{
            padding-right: #{$margenes + 20px};
        }
    }
}
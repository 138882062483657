.imagen-texto{
    padding: 0;
    align-items: flex-start;
    margin: 0;
    align-items: flex-start;
    padding-top:20px;

    .texto-imagen{
        padding-top:20px;
        @include desktop{
            padding-top: 0px;
        }
        ul{
            padding-inline-start: 1em;
        }

        h3{
            @include fuente-magma-titulos-notas(1.2em);
            padding-bottom: 15px;
        
            &:nth-of-type(n+2){
                padding-top: 15px;
            }
        }
    }

    .imagen-imagen{
        padding: 20px;
        img{
            max-width: 100%;
        }
    }

    &.derecha{
        flex-direction: row-reverse;;
    }

    &.abajo{
        align-items: flex-end;
        align-self: flex-end;
    }

    &.centro{
        align-items: center;
        align-self: center;
    }


    &.arriba{
        &.centro{
            text-align: center;
        }
        .imagen-imagen{
           padding:0px;
           padding-right: 5px;
        }
    }
    
    @include desktop{

        &.odd{
            padding-left: #{$margenes + 20px};
        }

        &.even{
            padding-right: #{$margenes + 20px};
        }

        &.full{
           padding: 20px #{$margenes + 20px};
        }
    }
}
.filter-container {
    .titulo {
        background-repeat: repeat-x;
        display: inline-flex;
        width: 100%;
        margin: 10px 0;
        height: 23px;
        position: relative;

        @include fuente-magma-titulos-notas(1.2em);

        width: auto;
        font-weight: 400;
        text-transform: capitalize;

        span {
            padding: 0 10px;
            text-transform: uppercase;
            font-size: .8em;
            line-height: 23px;
            margin-top: 50px;
        }
    }

    .search-container {
        position: relative;
        width: 100%;

        .search-icon {
            position: absolute;
            right: 15px;
            bottom: 15px;
            color: cssvar(primary-icon);
            z-index: 1;
        }

        input.form-control {
           width: 100%;
        }

        .input-container {
            width: calc(100% - 40px);
        }
    }

    .result-container {
        .advanced {
            display: inherit;
            text-align: right;
        }

        margin-top: 20px;

        p:not(.markdown) {
            padding-left: 25px;
        }
    }

    .filter-container {
        @include tablet {
            margin: 0;
        }


        @include desktop {
            margin: 0;
        }


        h4 {
            color: cssvar(headlines);
            font-size: 1.2em;
        }

        h3.titulo {
            display: none;
        }

        .container-fluid {
            padding: 0;
        }

        .borrar {
            margin-left: auto;
            justify-self: flex-end;
            display: flex;
            margin-right: 20px;
        }
    }
}

.carousel {
    transition: all 1s;
    overflow: hidden;

    .carousel-control-next, .carousel-control-prev {
        width: #{$margenes};
        top: 0;
        opacity: 1;
        transition: all .5s;
        background: transparent;
        justify-content: space-between;
        margin-top: 30px;
        height: 40px;
        align-items: flex-start;

        @include desktop {
            align-items: center;
            margin-top: 0px;
            height: auto;
        }


        @include tablet {
            align-items: center;
            margin-top: 0px;
            height: auto;
        }


        &.carousel-control-next {
            transform: scaleX(-1);
        }

        .carousel-control-next-icon, .carousel-control-prev-icon {
            width: 2em;
            height: 1em;
            font-size: 1em;
            background: cssvar(primary-cta);
            display: flex;
            justify-content: center;
            align-items: flex-start;

            @include mask($arrowImage);
           
            transform: rotate(-90deg);

            @include tablet {
                width: #{$margenes * 2};
                height: #{$margenes / 2};
            }


            @include desktop {
                width: #{$margenes * 2};
                height: #{$margenes / 2};
            }
        }

        &:hover {
            .carousel-control-next-icon, .carousel-control-prev-icon {
                background: cssvar(primary-cta-hover);
            }
        }
    }

    .carousel-inner {
        position: initial;
        margin: 0 auto;
        width: 100%;
    }

    h5 {
        text-transform: capitalize;
        padding: 10px 0;
    }

    .carousel-indicators {
        margin: 0;
        display: none;
        padding: 0 $margenes;
        bottom: 0;

        li {
            background: cssvar(primary-cta);
            margin: 0;
            height: 12px;
            flex: 1 1 0;

            &:hover, &.active {
                background: cssvar(primary-cta-hover);
            }
        }

        @include desktop {
            display: flex;
        }


        @include tablet {
            display: flex;
        }
    }

    .carousel-item {
        .carousel-row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 20px 0 0 0;
            justify-content: center;
            flex: 1 1 0;

            margin-top: 40px;

            & > div {
                padding: 0;
                flex: 1 1 0;
                width: 100%;
                max-width: 100%;
            }

            &.con-modal {
                cursor: pointer;
            }

            @include desktop {
                margin: 0;
            }


            @include tablet {
                margin: 0;
            }


            .galeria-item {
                margin: 0;
            }
        }

        @include desktop {
            padding: 0 #{$margenes};
        }

        @include tablet {
            padding: 0 #{$margenes};
        }


        & > div {
            position: relative;
            margin: 20px 0;
            flex: initial;
            max-width: initial;
            padding: 0;

            @include desktop {
                padding: 0;
            }


            @include tablet {
                padding: 0;
            }
        }
    }

    .hidden-carousel-item {
        visibility: hidden;
    }

    .edi-item {
        .card {
            img {
                height: 250px;
            }
        }

        .context-menu {
            img {
                height: 250px;
            }
        }
    }

    .carousel-numbers {
        @include fuente-magma-titulos-notas(1.5em);

        font-weight: normal;
        list-style: none;
        justify-content: center;
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        margin-top: 20px;

        @include desktop {
            display: none;
        }


        @include tablet {
            display: none;
        }
    }
}

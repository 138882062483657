/*!
 * Variables
 */ 

$tablet-width: 768px;
$desktop-width: 992px;
$altoMenu: 160px;
$altoMenuMobile: 60px;

$anchoMenu: 45px;

$count: infinite;
$animationSpeedRaw :.4;
$animationSpeed : #{$animationSpeedRaw}s;
$maxWidth: 1300px;
$margenes: 50px;


//LOGOS
$solImage: "./assets/decorativo/sol.svg";
$lunaImage: "./assets/decorativo/luna.svg";
$portalImage: "./assets/decorativo/magmaportal.svg";
$logoImage: "./assets/Logo/logo-proyecto-magma.svg";


//ELEMENTS:
$arrowImage: "./assets/decorativo/bttn_arrow.svg";
$buttonEnd: './assets/decorativo/bttn_decor_2.svg'; // SHARE END
// $buttonEnd: $arrowImage;// './assets/decorativo/bttn_decor_2.svg';

$buttonPrev: './assets/decorativo/boton_prev.svg'; //TABS
$buttonBorderImage: './assets/decorativo/tag_border.svg';
$buttonBorderImageEnd: './assets/decorativo/tag_border_2.svg';
$buttonBorderImageEnd2: './assets/decorativo/tag_border_3.svg';


//DIVIDERS:

$defaultDividerImage: './assets/decorativo/franja_5-.svg';
$defaultDividerImageIcon: './assets/decorativo/cabeza.svg';

$uneteDividerImage: './assets/decorativo/serpientes_V2.svg';

$zonaDividerImage: './assets/decorativo/ptrn_magma_uneteportal.svg';
$zonaDividerImageIcon: './assets/decorativo/ptrn_magma_uneteportal_fin.svg';

$cocreadoresDividerImage: './assets/decorativo/barra_cocreadores.svg';
$cocreadoresDividerImageIcon: './assets/decorativo/barra_cocreadores_cabeza.svg';

$buscarDividerImage: $defaultDividerImage;
$buscarDividerImageIcon: './assets/decorativo/ptrn_magma_buscar_fin.svg';

$proyectoDividerImage: './assets/decorativo/chakanaV2.svg';

$portalDividerImage: './assets/decorativo/nosotros_bloque.svg';

.collapsable-wrapper {
    $width: 100%;

    margin-bottom: 15px;
    width: 100%;

    img {
        max-width: 100%;
    }

    .collapse-button.btn {
        @include fuente-magma-titulos-notas(1em);

        background: transparent;
        width: 100%;
        border: none;
        display: flex;
        padding: 20px 0px;
        justify-content: flex-start;
        align-items: center;
        width: calc(100%);
        position: relative;

        .container {
            display: flex;
            justify-content: space-between;

            @include desktop {
                padding: 0 #{$margenes + 20px};
            }


            span {
                margin-right: auto;
                width: auto;
                display: inline-flex;
                background: transparent;
                text-align: left;
                margin: 20px 0;
                font-size: 1.6em;
                font-weight: 400;
                text-transform: uppercase;
                padding: 0px;
                white-space: pre-wrap;
                max-width: 90%;
                width: calc(100% - 60px);
            }

            i {
                font-size: .8em;

                @include mask($arrowImage);

                width: 52px;
                height: 40px;
                background: cssvar(primary-cta-active); 
                margin: 20px 0 0 10px;
                transition: background-color $animationSpeed;

                &.closed {
                    transform: rotate(180deg);
                }
            }

            span, i {
                color: cssvar(primary-cta-active);
            }
        }

        &:before, &:after {
            content: "";
            position: absolute;
            height: 1.8em;
            width: calc(100% - 93px);
            left: 0;
            top: 3px;
            z-index: -1;

            @include mask($defaultDividerImage);

            mask-repeat: repeat-x;
            transition: background-color $animationSpeed;
            background: cssvar(primary-cta-active);
        }

        &:after {
            @include mask($defaultDividerImageIcon);

            height: 2.8em;
            transform-origin: 0 0%;
            z-index: -1;
            background: cssvar(primary-cta-active);
            left: initial;
            width: 70px;
            top: -5px;
            right: 20px;
            z-index: 1;
        }

        @include desktop {
            &:before {
                width: calc(100% - 112px);
            }

            &:after {
                right: 34px;
                left: initial;
                width: 74px;
                top: -4px;
            }
        }


        @include tablet {
            &:before {
                width: calc(100% - 112px);
            }

            &:after {
                right: 40px;
                left: initial;
                width: 66px;
                top: -4px;
            }
        }


        &.hide-header {
            &:before, &:after, .container {
                display: none;
            }
        }

        &.collapse-unete {
            &:before {
                @include mask($uneteDividerImage);
                mask-repeat: repeat-x;
            }
        }

        &.collapse-zona_de_interaccion {
            &:before {
                @include mask($zonaDividerImage);
                mask-repeat: repeat-x;
            }

            &:after {
                @include mask($zonaDividerImageIcon);

                right: 23px;

                @include desktop {
                    right: 38px;
                }


                @include tablet {
                    right: 38px;
                }
            }
        }

        &.collapse-cocreadores {
            &:before {
                @include mask($cocreadoresDividerImage);
                mask-repeat: repeat-x;
            }

            &:after {
                @include mask($cocreadoresDividerImageIcon);

                right: 35px;
                width: 56px;

                @include desktop {
                    right: 54px;
                }


                @include tablet {
                    right: 54px;
                }
            }
        }

        &.collapse-buscar {
            &:before {
                @include mask($buscarDividerImage);
                mask-repeat: repeat-x;
                transform: scaleY(-1);
            }

            &:after {
                @include mask($buscarDividerImageIcon);

                right: 18px;
                width: 74px;
                top: -6px;

                @include desktop {
                    right: 37px;
                }


                @include tablet {
                    right: 37px;
                }
            }
        }

        &.collapse-proyecto_magma {
            &:before {
                @include mask($proyectoDividerImage);
                mask-repeat: repeat-x;
                height: 2.5em;
            }

            span, i {
                margin-top: 40px;
            }
        }

        &.collapse-portal {
            &:before {
                @include mask($portalDividerImage);
                mask-repeat: repeat-x;
            }
        }

        &.collapse-proyecto_magma, &.collapse-unete, &.collapse-portal {
            &:before {
                width: calc(100%);
            }

            &:after {
                width: 0;
            }
        }

        &.collapse-simple {
            &:before, &:after {
                display: none;
            }

            .container {
                flex-direction: row-reverse;
                place-content: flex-end;

                i {
                    margin-right: 20px;
                }

                span {
                    @include fuente-magma-titulos-notas(1.2em);

                    color:cssvar(primary-cta-active);
                    text-transform: initial;
                }
            }
        }


        &:hover {
            .container {
                span {
                    color:cssvar(primary-cta);
                }

                i {
                    background: cssvar(primary-cta);
                }
            }

            &:before, &:after {
                background: cssvar(primary-cta);
            }
        }

        &[aria-expanded="true"] {
            .container {
                span {
                    color:cssvar(primary-cta);

                }

                i {
                    background: cssvar(primary-cta);
                }
            }

            &:before, &:after {
                background: cssvar(primary-cta);
            }

            &:hover {
                .container {
                    span {
                        color:cssvar(primary-cta-active);

                    }

                    i{
                        background: cssvar(primary-cta-active);
                    }
                }

                &:before, &:after {
                    background: cssvar(primary-cta-active);
                }
            }
        }
    }

    .collapse.container, .collapsing.container {
        transition: height 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);

        @include desktop {
            padding: 0 #{$margenes + 20px};
        }


        &.collapse-proyecto_magma, &.collapse-cocreadores {
            @include tablet {
                padding: 20px;
            }


            @include desktop {
                padding: 20px;
            }
        }

        &.show {
            transition-timing-function: ease-in-out;
        }
    }
}

.suscribete-home{
    padding: 0;
    
    .form-container{
        h4 {
            padding-top: 30px;
            font-size: 1.2em;
        }

        .register-form{
            flex-direction: row;
            justify-content: space-between;
            padding: 15px;

            input{
                border-color: cssvar(primary-text);                
            } 
           
            .msg-alert p{
                color: cssvar(primary-text) !important
            }
        
            button{
                @include mobile{
                   margin-left: 5px;
                }
            }
        }
    }

}

.suscribete{
    background: cssvar(primary-text);
    padding-bottom: 20px;

    .suscribete-interna{
        @include desktop{
            padding: 5px #{$margenes - 20px};
        }

        &.form-container{
            h4{
                color: cssvar(main-background);
                padding-top: #{$margenes - 30px};
                font-size: 1.2em;
            }
        
            label{
                color: cssvar(main-background);
            }
        

            .register-form{
                flex-direction: row;
                justify-content: space-between;
                padding: 15px;
            
                input{
                    color:  cssvar(main-background);
                    border-color: cssvar(main-background);

                    &::placeholder {
                        color: cssvar(main-background);
                    }
                } 

                .msg-alert p{
                    color: cssvar(main-background) !important
                }

                button{
                    @include mobile{
                       margin-left: 5px;
                    }
                }
            }
        }      
    }
}

.encuestas{
    margin-top: 20px;

    label{
        display: inline;
    }
}

.collapse-zona_de_interaccion{
    .texto-imagen{
        .cuerpo-containers.full{
            padding: 0;
        }
    }
}
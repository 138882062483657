body {
    @include fuente-magma-textos(1em);

    background: cssvar(main-background);
    transition: all #{$animationSpeedRaw * 3}s;

    main {
        // margin-top: calc(#{$altoMenuMobile + 20px});
        margin-left: auto;
        margin-right: auto;
        width: 100vw;

        @include mobile {
            margin-top: $altoMenuMobile;
        }
        
        @include desktop {
            //   margin-top: calc(#{$altoMenu + 20px});
            width: auto;
        }


        &.interna {
            margin-top: calc(#{$altoMenuMobile + 20px});
        }
    }

    a, svg, .nav-link,
    .btn-primary:not(:disabled):not(.disabled),
    .btn-secondary:not(:disabled):not(.disabled),
    .btn-terciary:not(:disabled):not(.disabled),
    .btn-inverted:not(:disabled):not(.disabled),
    .btn-bullet:not(:disabled):not(.disabled),
    .btn-simple:not(:disabled):not(.disabled),
    .badge-primary {
        transition: all $animationSpeed;

        &:after, &:before {
            transition: all $animationSpeed;
        }
    }

    //p, h3, span

    a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):not(.btn-secondary):hover {
        color: cssvar(primary-cta);
        cursor: pointer;

        &:hover, &.active, i {
            color: cssvar(primary-cta);
            text-decoration: none;
        }
    }

    // a {
    //     &:hover {
    //         color: cssvar(primary-cta-hover);
    //         text-decoration: none;
    //     }  
    // }

    h3, p, a {
        // &:hover {
        //     color: cssvar(primary-cta-hover);
        //     text-decoration: none;
        // }

        h3 {
            color: cssvar(headlines);

            p {
                color: cssvar(headlines);
            }
        }

        p {
            color: cssvar(primary-text);
        }
    }

    // button{
    //     background: $colorBotonesActivos;
    //     border-color: $colorBotonesActivos;
    // }

    .container {
        max-width: $maxWidth;

        @include desktop {
            // padding:0;
        }
    }

    .image-container {
        img.img-responsive {
            max-width: 100%;
        }
    }

    .modal {
        width: 100%;
        left: 0;
        top: 0;
        padding: 0 !important;

        &.show {
            display: flex !important;
            justify-content: center;
            align-items: center;
            z-index: 9999;
            background: rgba(cssvar(primary-text), 0.7);

            .modal-dialog {
                max-width: 100%;
                margin: 0;

                .modal-content {
                    background: cssvar(main-background);
                    border: none;
                    border-radius: initial;
                    outline: none;
                    width: 100vw;
                    height: 100vh;

                    .modal-header {
                        border-bottom: 2px solid cssvar(headlines);

                        .modal-title {
                            @include fuente-magma-titulos(1.5em);
                        }

                        button.close {
                            color: cssvar(headlines);
                            opacity: 1;

                            &:before {
                                content: '\e08c';
                                position: absolute;
                                font: normal normal normal 24px/1 'iconmonstr-iconic-font';
                                top: 20px;
                                right: 20px;
                                text-shadow: none;
                            }

                            span {
                                display: none;
                            }
                        }
                    }

                    .modal-body {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-height: 90%;

                        picture {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            position: relative;

                            img {
                                height: auto;
                                max-height: 100%;
                            }

                            .caption {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                background-color: rgba(cssvar(primary-text), 1);
                                width: 100%;
                                color: cssvar(main-background);
                                margin: 0;
                                text-align: right;
                                padding-right: 5px;
                            }
                        }

                        .carousel {
                            width: 100%;
                            max-width: 1200px;

                            .carousel-row {
                                text-align: center;
                            }
                        }
                    }
                }
            }

            img {
                max-width: 100%;
                width: auto;
            }
        }
    }

    #container2 #arrow {
        display: none !important;
    }

    #container #arrow {
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid cssvar(primary-cta);
    }
}

* {
    scrollbar-width: thin;
    scrollbar-color: cssvar(main-background) cssvar(primary-text);
}

/*SCROLL BAR */
*::-webkit-scrollbar {
    width: 0;
    cursor: pointer;

    @include desktop {
        width: 1em;
    }
}

*::-webkit-scrollbar-track {
    box-shadow: none;
    cursor: pointer;
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: cssvar(primary-cta);
    outline: none;
    cursor: pointer;
}

.cuerpo-containers.video-container,
.cuerpo-containers.sound-container {
    .contenedor-video {
        position: relative;
        margin: 0;
        align-items: flex-start;

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
        }

        .video-overlay {
            transition: all $animationSpeed;

            img {
                width: 100%;
            }

            button {
                position: absolute;
                left: 0;
                top: 0;
                padding: 0 !important;
                width: 100%;
                z-index: 1;

                .icon-container {
                    position: absolute;
                    z-index: 1;
                    top: calc(50% - 30px);
                    right: 0;
                    background: css(primary-cta);
                    display: flex;
                    padding: 0 5px;
                    align-items: center;
                    background-color: cssvar(primary-cta);

                    @include fuente-magma-titulos-notas(1.5em);

                    transition: all $animationSpeed;

                    span {
                        color: cssvar(main-background);
                        padding-right: 5px;
                    }

                    i {
                        color: cssvar(main-background);
                        padding-top: 5px;
                    }
                }
            }

            opacity: 1;

            &:hover {
                button {
                    .icon-container {
                        background-color: cssvar(primary-cta-hover);
                    }
                }

                opacity: .8;
            }
        }

        .caption {
            display: none;
        }

        &.poster-invisible {
            .video-overlay {
                opacity: 0;
            }

            iframe {
                z-index: 1;
            }
        }
    }

    @include desktop {
        &.odd {
            padding-left: #{$margenes + 20px};
        }

        &.even {
            padding-right: #{$margenes + 20px};

            .video-overlay {
                .icon-container {
                    right: initial !important;
                    left: 0;
                }
            }
        }
    }
}



.cocreadores-container{
    width: 100%;
    padding: 0;
    background-position-x: 20px;
    margin: 0 auto;
    overflow: hidden;
    
    .cocreadores-wrapper{
        display: inline-block;
        padding-left: 100%;
        padding: 20px 0;
        font-size: 1.2em;
        span{
            font-weight: 600;
        }

        a{
            color: cssvar(primary-text);
            &:hover{
                color: cssvar(primary-cta);
            }
        }
    } 

    .carousel-container{
        .cocre-item{
            position: relative;
            margin-bottom:20px;
            padding-bottom:20px;
            h5{
                text-transform: capitalize;
                padding:10px 0;
                white-space: pre-wrap;
            }
            .categorias-container{
                padding: 0;
                .categorias-wrapper{
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                }
                button{
                    margin-bottom: 5px;
                }
            }

        }
    }
}

//ANIMACION EN LA HOME
#cocreadores{
    .show{
        .cocreadores-wrapper{
            animation: marquee 10s linear $count;
        }
    }
    .container{
        position: relative;
        .cocreadores-container:first-of-type{
            position: absolute;
            top:-90px;
            width: calc(100% - 400px - #{$margenes});
            left:280px;
        }

        .cocreadores-wrapper{
            white-space: nowrap;
        }
    }
    
}


.text-home-cocreadores{
    padding: 0;
    @include desktop{
        padding: 0 #{$margenes + 0px};
    }

    button{
        margin-right: 30px;
        margin-top: 20px;
    }
}

.cocreador-mosaico{
    font-size: .8em;
    padding: 15px;

    @include desktop{
        padding: 0
    }
    
    .cocreadores-container {
        .cocreadores-wrapper{
            display: flex;
            flex-wrap: wrap;
            @include desktop{
                padding: 20px #{$margenes + 20px};
            }
        }
    }

    .cocre-item{
        padding: 0;
    }

    .card{
        &.default{
            padding: 0;  
        }
        
        &.destacada{
            &:before{
              content: '';
              background: cssvar(primary-icon);
              position: absolute;
              top: 20px;
              right: 20px;
              height: 10px;
              width: 10px;
              border-radius: 100%;
              z-index: 1;
            }
          }
    }
}

.cocreadores-interna{
    .texto-container.odd.even{
        @include desktop{
            padding: 20px #{$margenes + 20px};
        }
    }
}




.categorias-wrapper{  
    .item{
        display: flex;
        flex-wrap: wrap;
    }
    button{
        margin-right: 5px;
        margin-bottom: 5px;
        img{
            height:0;
            width:0;
        }
    }

    .btn-primary{
        margin-left: 50px;
    }
}
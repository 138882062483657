.proyecto {
    button.btn:not(:disabled):not(.disabled) {
        margin-top: 30px;
    }

    .texto-container.odd.even {
        @include desktop {
            padding: 20px #{$margenes + 30px};
        }

        &.bajada {
            position: relative;
            color: cssvar(secondary-text);
            padding-bottom: 80px;
            margin-bottom: 40px;

            &:after {
                content: "";
                position: absolute;
                height: 1.8em;
                width: calc(100% - #{$margenes + 100px});
                left: #{$margenes + 30px};
                bottom: 0px;
                z-index: -1;

                @include mask($defaultDividerImage);
                mask-repeat: repeat-x;
                background: cssvar(primary-cta);
            }
        }
    }
}

.carousel-nosotros {
    padding: 30px;

    @include desktop {
        padding: 30px;
    }
}

/**
 * ROOT VARIABLES
 */
 @function cssvar($name, $prefix: magma) {
    @return var(--#{$prefix}-#{$name});
  }
  
  @mixin rootcssvars($css_variables, $prefix: magma) {
    :root {
      @each $name, $value in $css_variables {
        --#{$prefix}-#{$name}: #{$value};
      }
    }
  }
  
  @mixin cssvars($css_variables, $prefix: magma) {
      @each $name, $value in $css_variables {
        --#{$prefix}-#{$name}: #{$value};
      }
  }

  // modifiers
  @mixin cssvar($name, $value: '', $prefix: magma) {
    --#{$prefix}-#{$name}: #{$value};
  }
  


/* SIZEs 
*/


@mixin mobile {
    @media (max-width: #{$tablet-width - 1px}) {
      @content;
    }
} 

@mixin mobile-landscape {
    @media (max-width: #{$tablet-width}) and (orientation : landscape) {
      @content;
    }
} 

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
      @content;
    }
}

@mixin tablet-up {
  @media (min-width: #{$tablet-width}) {
    @content;
    }
}

@mixin desktop {
@media (min-width: #{$desktop-width}) {
    @content;
    }
}

@mixin ancho-con-menu{
    width: calc(100% - #{$altoMenu});
    left: #{$altoMenu};
    top:0;
    height: 100%;

}

@mixin alto-con-menu{
    height: calc(100% - #{$altoMenu});
    top: #{$altoMenu};  
    left:0;
    width: 100%;
}

/*FONTS */

@mixin fuente-magma-titulos($tamano:1em){
  font-family: 'Russo One', sans-serif;
  font-size: $tamano;
  color: cssvar(headlines);
}

@mixin fuente-magma-titulos-notas($tamano:1em){
  font-family: 'Chakra Petch', sans-serif;
  font-size: $tamano;
  font-weight: bold;
  color: cssvar(headlines);
}

@mixin fuente-magma-textos($tamano:1em){
  font-family: 'rajdhani',sans-serif;
  font-size: $tamano;
  color: cssvar(primary-text);
}


@mixin fuente-magma-modal($tamano:1em){
  font-family: 'rajdhani',sans-serif;
  font-size: $tamano;
  line-height: $tamano;
  color: cssvar(primary-text);
}

@mixin fuente-magma-modal-titulos($tamano:1em){
  font-family: 'Russo One', sans-serif;
  font-size: $tamano;
  line-height: $tamano;
  color: cssvar(headlines);

}

@mixin mask($url:''){
  mask-image: url($url);
  mask-repeat: no-repeat;
  mask-size: auto;
  mask-position: center;
}

/* MATH FUNTIONS */


@function multiply-px($value1, $value2) {
  @return $value1 * $value2;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
      @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

@return $string;
}


@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1);
}

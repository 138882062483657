.galeria-container{
    padding-top: 20px;
    padding: 20px;
   
    @include desktop{
        
        &.full.simple{
            padding: 20px #{$margenes + 20px};
        }
    
        &.odd{
            padding-left: #{$margenes + 20px};
          }

        &.even{
            padding-right: #{$margenes + 20px};
        }
    }

    img{
        width: 100%;
    }

    .galeria-item{
        .caption{
            display: none;;
        }
    }

    .carousel .carousel-item .carousel-row {
        margin-bottom: -20px;
    }
}
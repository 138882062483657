/*BOTONES */
$buttonBackground: cssvar(primary-cta);
$buttonBackgroundHover: cssvar(primary-cta-hover);
$buttonText: cssvar(main-background);

.btn-primary,
.btn-secondary,
.register-form button,
.btn-inverted,
.btn-inverted:hover,
.btn-primary.disabled,
.btn-primary:disabled,
.show > .btn-primary.dropdown-toggle,
.btn-outline-primary:hover,
.btn-secondary:not(:disabled):not(.disabled).active:hover,
.register-form button:not(:disabled):not(.disabled).active:hover
 {
    color: $buttonText;
    background-color: $buttonBackground;
    border: none;
    font-weight: 600;
    box-shadow: none;
    position: relative;

    &:before, &:after {
        content: "";
        position: absolute;
        // mask-image: url("../../../assets/decorativo/tag.svg"), url("../../../assets/decorativo/bttn_decor_2.svg");
        // mask-size: auto, auto;
        // mask-repeat: no-repeat;
        // mask-position: center, center;
        @include mask($buttonBorderImageEnd);
        background: $buttonBackground;
        width: 20px;
        height: 2em;
        top: -4px;
        right: -21px;
    }
}

.btn-primary:hover,
.btn-secondary:hover,
.register-form button:hover,
.btn-inverted,
.btn-primary.active,
.btn-primary.active:hover,
.btn-terciary,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.register-form button:not(:disabled):not(.disabled).active,
.register-form button:not(:disabled):not(.disabled):active
 {
    color: $buttonText;
    background-color: $buttonBackgroundHover;
    border-color: $buttonBackgroundHover;
    box-shadow: none;

    &:before, &:after {
        background-color: $buttonBackgroundHover;
    }
}

// .btn-secondary:not(:disabled):not(.disabled).active:hover,
// .register-form button:not(:disabled):not(.disabled).active:hover
// {  
//   color: $colorTextoBotones;
//   background-color: $colorBotones;
//   border-color: $colorBotones; 
//   box-shadow: none;

//   &:before, &:after{
//     background-color: $colorBotones;  
//   }
// }

.btn-outline-primary,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    color: $buttonText;
    border-color: $buttonBackground;
    box-shadow: none;
}

.btn-bullet {
    border-radius: 50% !important;
    padding: 0;
    height: 20px;
    width: 20px;
    margin-top: 3px;
    background: $buttonBackground;
    border: 4px solid $buttonBackground;
    color: $buttonBackgroundHover;
    margin-left: 5px;
    box-shadow: none;
}

.btn-bullet:hover {
    background: $buttonBackgroundHover;
    border-color: $buttonBackground;
}

.btn-link,
.btn-link:hover,
a {
    color: $buttonBackground;
    text-decoration: none;
}

a:hover {
    color: $buttonBackgroundHover;
    text-decoration: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn:focus,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none !important;
    border-color: transparent;
}

/*CONTROLES */

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: $buttonText;
    background-color: $buttonBackgroundHover;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: $buttonText;
    background-color: $buttonBackground;
    border: 1px solid $buttonBackground;
}

.page-link:hover {
    z-index: 2;
    color: $buttonText;
    text-decoration: none;
    background-color: $buttonBackgroundHover;
    border-color: $buttonBackgroundHover;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow:none;
}

.page-item.active .page-link {
    z-index: 1;
    color: $buttonText;
    background-color: $buttonBackgroundHover;
    border-color: $buttonBackgroundHover;
}

.badge-primary,
.btn-simple,
.btn-secondary,
.register-form button,
.btn-terciary {
    color: $buttonText; //$colorTextoBotones;
    background-color: $buttonBackground;
    height: 1.2em;
    padding: 0;
    margin: 5px 15px 5px 10px;
    font-size: 1em;
    line-height: 1.2em;
    transform: scale(1.1);
    border: none;
    height: auto;

    &:before, &:after {
        content: '';
        @include mask($buttonBorderImage);
        mask-position: unset;
        height: 100%;
        width: 1em;
        position: absolute;
        left: -.3em;
        background-color: $buttonBackground;
        top: 0px;
        z-index: -1;
    }

    &:after {
        left: initial;
        right: -.3em;
        transform: scaleX(-1);
    }

    i {
        margin: 2px;
    }
}

.btn-secondary,
.register-form button {
    &:before {
        left: -.5em;
    }

    &:before, &after{
        mask-position: center;
    }
}

a.badge-primary:hover, a.badge-primary:focus, .btn-simple:hover, .btn-simple:focus {
    color: $buttonText;
    background-color: $buttonBackgroundHover;

    &:before, &:after {
        background-color: $buttonBackgroundHover;
    }
}

.btn-secondary,
.register-form button,
.btn-terciary {
    &:before, &:after {
        content: '';
    }

    &:after {
        @include mask($buttonBorderImageEnd);
        right: -1.4em;
        width: 1.5em;
        height: 100%;
        transform: scaleX(1);
    }
}

.btn-terciary {
    color: $buttonText;
    background-color: $buttonBackgroundHover;

    &:before, &:after {
        content: '';
        @include mask($buttonBorderImageEnd2);
        left: -1.6em;
        width: 1.7em;
        transform: scaleX(-1);
        background-color: $buttonBackgroundHover;
    }

    &:after {
        @include mask($buttonBorderImageEnd2);
        right: -1.6em;
        width: 1.7em;
        left: initial;
        transform: scaleX(1);
    }
}

.btn-terciary:hover {
    color: $buttonText;
    background-color: $buttonBackground;

    &:before, &:after {
        background-color: $buttonBackground;
    }
}

.card {
    display: flex;
    align-items: flex-start;
    border: none;
    background: transparent;
    flex-direction: column;

    @include desktop {
        flex-direction: row;
        padding: 15px;
    }


    @include tablet {
        padding: 15px;
    }


    .card-text {
        width: 100%;
        padding: 10px;
        padding: 0;
        margin-bottom: 20px;

        .markdown.text {
            color:cssvar(primary-text);
        }
    }

    h3 {
        @include fuente-magma-titulos-notas(1.5em);

        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;

        &.numero {
            span {
                font-size: 2em;
            }
        }

        @include desktop {
            font-size: 1.7em;
        }
    }

    .card-image {
        overflow: hidden;
        display: block;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;

        img {
            display: block;
            object-fit: cover;
            height: 150px;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }
    }

    .categorias-wrapper {
        button {
            cursor: initial;
        }
    }

    /// OVERRIDE CAPTION -
    .con-caption {
        position: relative;

        .caption {
            display: none;
        }
    }

    &.tile {
        flex-direction: column;

        .card-text {
            padding: 10px;

            a {
                height: 100%;
                display: block;
            }

            h3 {
                font-size: 1.3em;
            }

            @include mobile {
                position: relative;
                width: 100%;
                bottom: initial;
            }
        }

        img {
            height: 300px;
            width: 100%;
        }

        &:hover {
            .card-text {
                height: calc(100% - 30px);
            }
        }
    
        .container{
            display: none;
        }

        .card-image {
            width: 100%;
            height: 0;
            padding-top: 100%;
        }

    }

    &.default {
        flex-direction: column;

        .card-text {
            padding: 0 15px;
        }

        h3 {
            @include fuente-magma-textos(2em);
            display: flex;
            flex-wrap: wrap;
            margin: 20px 0 0;
            text-transform: initial;
            font-size: 1.2em;
            color: cssvar(headlines);
        }


        .categorias-wrapper {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            @include mobile {
                flex-direction: row;
            }
        }

        .card-image {
            img {
                transition: none;
                object-fit: cover;
                mask-composite: destination-out;
                overflow: hidden;
            }

            &:hover {
                @include mask-linear-animation(linear, 100%, 100%, #{$animationSpeedRaw * 5}s, infinite);
            }
        }
    }

    &.simple-image {
        flex-direction: column;

        h3 {
            font-size: 1.5em;
            margin: 10px 0;
            text-transform: initial;
        }
    }

    // ANIMATIONS

    &.simple-image {
        .card-image {
            width: 100%;

            img {
                mask-repeat: no-repeat;
                mask-position: 50%;
                background: transparent;
                min-height: 100%;
                height: 200px;
            }
        }

        &:hover {
            background: transparent;

            img {
                @include mask-magma-animation(2000, 5%, 2000%, #{$animationSpeedRaw * 3}s, 1);
            }
        }


    }

    &.compact {

        flex-direction: column;
        .card-image {
            margin-right: 20px;
            width: 100%;
            height: 0;
            padding-top: 100%;

            img {
                height: 100px;
                width: 200px;
            }
        }

        .card-text{
            margin-top: 5px;
        }
    }

    &:hover {
        h3, p {
            color: cssvar(primary-cta-hover);
        }

        .badge-primary {
            background: cssvar(primary-cta-hover);
            color: cssvar(main-background);

            &:before, &:after {
                background-color: cssvar(primary-cta-hover);
            }
        }
    }
}

/* ESTO ES PARA LAS TABS */
.selecting {
    .card {
        img {
            @include mask-magma-animation(100, 5%, 2000%, #{$animationSpeedRaw * 1.5}s, 1);
        }
    }
}

$time: $animationSpeedRaw * 6;
$time2: #{$time}s;

.loading-container {
    .loading {
        display: flex;
        min-height: 250px;
        justify-content: center;
        align-items: center;

        .loader-image {
            height: 50px;
            margin: 0 auto;
            fill: cssvar(primary-cta-active);

            .dot-1 {
                animation: anim 1s linear 0s infinite;
            }

            .dot-2 {
                animation: anim 1s linear 0.25s infinite;
            }

            .dot-3 {
                animation: anim 1s linear 0.50s infinite;
            }
        }

        @keyframes anim {
            0% {
                transform: translateY(0);
            }

            50% {
                transform: translateY(-5px);
            }

            100% {
                transform: translateY(0);
            }
        }
    }

    &.full {
        background: cssvar(secondary-background);
        height: 100%;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        //animation: eclipse-full-background 10s infinite;

        .loading {
            position: relative;
            background: transparent;
            display: initial;
            width: 400px;
            width: 170px;
            min-height: 30%;
            margin-top: -405px;
            height: 595px;
            mask: none;

            &:before {
                content: '';
                position: absolute;

                @include mask($logoImage);

                animation: eclipse-full-background 8s infinite;

                background: cssvar(primary-cta);
                height: 500px;
                width: 100%;
            }

            &:after {
                content: '';
                position: absolute;
                line-height: 1.2em;
                font-size: 1.2em;
                z-index: -1;
                
                @include mask($portalImage);

                bottom: -156px;
                background: cssvar(primary-cta);
                height: 154px;
                bottom: 114px;
                height: 250px;
                z-index: 1;
                width: 80%;
                left: 10%;
                animation: eclipse-full-background 8s infinite;

            }
        }

        .eclipse {
            position: relative;
            height: 160px;
            width: 170px;
            margin-bottom: 150px;
            z-index: 99999;

            &:before {
                content: "";

                @include mask($solImage);
                mask-size: 80px;           

                background: cssvar(primary-cta);
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                position: absolute;
                animation: eclipse-full-background 8s infinite;

            }

            &:after {
                content: "";
                @include mask($lunaImage);
                mask-size: 60px;

                background: cssvar(primary-cta-active);
                width: 100%;
                height: 80%;
                top: 10%;
                transition: left $animationSpeed;
                position: absolute;
                left: -100px;
                animation: eclipse-full 10s infinite;
            }
        }
    }

    .lista {
        height: 200px;
        width: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        position: absolute;
        bottom: 0;

        $pLength: 50;

        span {
            position: absolute;
            word-break: break-all;

            @include color-change-animation(color, $time, 1);

            opacity: 0;
            line-height: 1em;
            font-size: 3em;
            text-align: center;
            width: 100%;
            background: transparent !important;

            @for $i from 1 through $pLength {
                &:nth-of-type(#{$i}) {
                    animation-delay: multiply-px($i, $time) - $time + s;
                }
            }
        }
    }
}

#interactivo{
  margin: 0;
  padding: 0;
  margin-bottom: -10px;

  .collapse.container{
    padding: 0;
    max-width: 100%;

    .interactivo{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column-reverse;
      min-height: 50vh;
      height: 80vh;
      width: 100%;
    
      iframe{
        width: 100%;
        height: 100%;
        overflow: hidden;
        border: none;
        border: 0;
      }   
    }
  }
  .collapse-button.btn{
    display: none;
  }
}

.resiste{
    p{
    // @include fuente-magma-textos(1.2em);
    //color: $colorPpal;
    text-transform: none;
    margin-bottom: 10px;
    }

    p:not(.markdown){
      display: inline-block;
    }
    
    b{
      display: inline;
    }



    .donacion-container{
      padding: 40px 30px;
      flex-direction: column;
      justify-content: start;
      align-items: center;

      @include desktop{
        &:first-of-type{
          padding-left: $margenes;
        }
        &:last-of-type{
          padding-right: $margenes;
        }
      }

      h3{
        @include fuente-magma-titulos-notas(1em);
        text-align: center;
        img{
          width: 50%;
          padding:0;
          text-align: center;
        }
      }

      img{
        max-width: 240px;
        padding: 15px;
        width: 100%;
      }

      a.btn-secondary{
        margin-top: 30px;
      }

      h6{
        @include fuente-magma-titulos-notas(1em);
        text-transform: initial;
        font-weight: normal;
      }
    }
}

.tabs-resiste{
  padding: 20px 30px;
  @include desktop{
    padding: 20px calc(20%);
  }

  .nav-tabs{
    font-size: .8em;
    padding: 10px 0;
  }

  .tab-content{
    .container{
      padding: 0;
    }
  }

}

 
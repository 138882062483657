.nav-tabs{
    border:none;

    .nav-link{
        border:none;
        text-transform: uppercase;
        font-weight: bold;
        color: cssvar(main-background);
        background: cssvar(primary-cta);
    
        border-radius: initial;
        position: relative;
        margin: 10px 0px 0px 20px;
        padding: 4px; 

        &:before{
            content:'';
            width: 10px;
            height: 100px;
            background-color: cssvar(primary-cta);

            @include mask($buttonPrev);
            height: 2em;
            position: absolute;
            left:-15px;
            z-index: -1;
            top:0;
            transition: all 1s;
        }

        &.active, &:hover{
            background: cssvar(primary-cta-active);
            color: cssvar(main-background);
    
            &:before{
                background: cssvar(primary-cta-active);
            }
        }
    }
}
.ediciones-container{
    .card.default{
        padding: 0;
    
    
        .text-edicion {
            h3 {
                text-transform: uppercase;
                margin-top: 0;
            }
    
            p, span {
                text-transform: capitalize;
                color: cssvar(primary-text);

            }
        }
    }
}

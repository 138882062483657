@import './variables.scss';
@import "./_helpers.scss";
@import './themes/variables';
@import './reset.scss';

html:not(.is-light){
    @import './themes/dark-theme-juez.scss';
}

html.is-light{
    @import './themes/light-theme-juez.scss';
}

@import 'styles.scss';

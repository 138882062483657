.separator{
  order: 2;
  order: 2;
  border-bottom: 2px solid cssvar(primary-cta);
  padding-top: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.dropdown{
  .related-list-item{
    display: inline-flex;
    &.active{
      display: none;
    }
  }
}

.related-list-item{
    display: inline-flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
    order: 2;
    
    @include mobile{
      display: none;
      width: 100%;
    }

    opacity: .7;
    &.active{
      order: 1;
      opacity: 1;
      display: inline-flex;

      &:last-child{
        clear: left;
      }
    }

    p{
      margin:0;
      padding-right:5px;
    }

    &:hover, &.active{
        p{
          color: cssvar(primary-cta-active);
        }

        .btn-bullet{
          background: cssvar(primary-cta-active);
          border-color:cssvar(primary-cta);

        }
    }
  }
  
  // .add-rel-item{
  //   // input{
  //   //  // border: 1px solid red;
  //   // }
  // }

  $width: 100px;

  .ver-mas{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    font-size: 1.6em;
    padding: 10px;
  }
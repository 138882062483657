header {
    height: $altoMenuMobile;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;

    @include desktop {
        height: $altoMenu;
    }

    &.header-reduced{
        @include desktop {
            height: #{$altoMenuMobile / 2};
        } 
    }


    &.header-simple {
        padding: 0;
        margin: 0;
        height: $altoMenuMobile;
        left: 0;

        .navbar {
            align-items: center;
            max-height: 100px;

            .change-color {
                top: 0;
                position: relative;
                height: 75px;
                left: initial;
            }
        }

        .back-button {
            margin: 10px 0;
        }

        h1 {
            text-align: center;

            @include fuente-magma-titulos-notas(2.5em);

            max-width: 80%;
            padding-left: 0;
            font-size: 1.2em;
            padding-right: 0;
            word-break: break-word;

            @include tablet {
                padding-right: auto;
            }


            @include desktop {
                font-size: 2.5em;
                padding-right: 0;
            }
        }

        div {
            margin: 0;
            padding: 0;

            label {
                margin: 0 !important;
                position: absolute;
            }

            input.form-control {
                @include fuente-magma-titulos-notas(2.5em);
                text-align: center;
                padding: 0;
                line-height: 1em;
                max-width: 90%;
            }
        }

        .navbar-brand {
            padding-top: 0;
            max-width: 8.333333%;
        }
    }

    .header-links {
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 2;
        width: 100%;
    }

    .logos {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    &.new-header {
        @include fuente-magma-titulos(1em);

        align-items: center;
        text-transform: uppercase;
        width: 100%;
        position: absolute;

        @include mobile {
            position: fixed;
            background: cssvar(secondary-background);
        }


        .header-links {
            background: cssvar(primary-cta);

            .nav-item a, .redes a {
                color: cssvar(main-background);
                padding: 0.5rem 0.2rem;

                @include desktop {
                    padding: 0 10px;
                }
            }

            @include mobile {
                display: none;

                &.opened {
                    display: block;
                    height: 100%;
                    background: cssvar(secondary-background);
                    padding-top: $altoMenuMobile;
                    position: fixed;
                    height: 100%;
                    width: 100%;

                    .nav-item a, .redes a {
                        color: cssvar(primary-cta);
                    }
                }
            }


            .redes {
                margin-top: 5px;

                a {
                    padding: 0;
                    display: inline-flex;
                    padding-left: 10px;
                    font-size: 1em;

                    .im {
                        font-size: 1em;
                    }
                }
            }
        }

        .toggle {
            display: none;
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 99999;
            cursor: pointer;

            @include mobile {
                display: block;
            }
        }

        .logos {
            padding: 0 20px;
            margin-top: 0;
            height: 100%;
            margin-left: 0px;

            > span {
                height: 40px;

                @include tablet-up {
                    height: auto;
                }
            }

            @include tablet-up {
                margin-top: 40px;
                height: 100px;
                margin-left: 40px;
                height: 70px;

                &.logos-hide {
                    display: none;
                }
            }

            @include desktop {
                margin-left: 26px;
            }

            .logo {
                svg {
                    width: 3.5em;
                    transition: background-position $animationSpeed;
                    fill: cssvar(primary-icon);
                    max-width: 100px;

                    @include tablet {
                        width: 6em;
                    }


                    @include desktop {
                        width: 8em;
                        max-width: 100%;
                    }
                }
            }

            &:hover {
                .logo {
                    svg {
                        fill: transparent;
                        background-size: 400% 400%;
                        
                        @include mask($logoImage);

                        background: cssvar(primary-icon);
                        animation: gradient .5s ease infinite;
                        animation-iteration-count: 1;
                    }
                }
            }

            .logo2 {
                position: absolute;
                top: 60px;
                right: 24px;
                line-height: 1.2em;
                font-size: 1.2em;
                z-index: -1;

                @include mask($portalImage);

                background: cssvar(primary-icon);
                height: 45px;
                width: 100px;
                display: none;

                @include desktop {
                    display: block;
                }
            }
        }
    }

    &.header-simple {
        position: fixed;
        top: 0;
        text-transform: uppercase;
        z-index: 2;
        background: cssvar(secondary-background);
        height: auto;
        width: 100%;
        min-height: $altoMenuMobile;

        .header-links {
            padding: 10px;
            position: absolute;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            background: cssvar(secondary-background);
        }

        .logos {
            position: absolute;
            top: 0;
            width: 100%;
            padding: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 99999;

            .change-color {
                position: relative;
                right: auto;
                top: -5px;
                right: 0;
                height: 50px;
                left: initial;
            }
        }
    }

    .change-color {
        position: absolute;
        z-index: 1;
        width: 60px;
        left: initial;
        align-items: center;
        display: flex;
        top: 0;
        height: 100%;
        left: 100px;

        @include tablet {
            right: 30px;
            left: initial;
            width: 100px;
        }


        @include desktop {
            right: 30px;
            left: initial;
            width: 100px;
        }


        .dark-light {
            clip-path: none;
            margin: 5px;
            height: 50px;
            width: 53px;
            background: transparent;
            width: 100px;

            @include mobile {
                position: relative;
            }


            &:after {
                content: "";
                @include mask($lunaImage);
                background: cssvar(light);
                width: 40px;
                height: 35px;
                left: 25px;
                top: 9px;
                transition: left $animationSpeed;
                position: absolute;

                @include desktop {
                    top: 9px;
                }
            }

            &:before {
                content: "";
                @include mask($solImage);

                background: cssvar(primary-icon);
                width: 100%;
                height: 50px;
                left: -8px;
                top: 0px;
                position: absolute;

                @include desktop {
                    left: -25px;
                    top: 0;
                }
            }

            &.btn-primary:not(:disabled):not(.disabled):active {
                background: transparent;
            }
        }

        &.hover {
            .dark-light {
                &:after {
                    animation: eclipse 4s;
                }
            }
        }

        &.clicked {
            &.is-dark {
                .dark-light {
                    &:after {
                        left: 5%;
                    }
                }
            }
        }
    }
}

.unete-red {
    padding-bottom: 30px;

    .bajada {
        position: relative;
        color: cssvar(secondary-text);
        margin-bottom: 40px;

        @include desktop {
            padding-bottom: 120px;
        }


        &:after {
            content: "";
            position: absolute;
            height: 1.8em;
            width: calc(100%);
            left: 0;
            bottom: -30px;
            z-index: -1;

            @include mask($defaultDividerImage);

            mask-repeat: repeat-x;
            background: cssvar(primary-cta);

            @include desktop {
                width: calc(100% - #{$margenes + 100px});
                left: #{$margenes + 30px};
            }
        }
    }

    .imagen-texto {
        h3 {
            @include fuente-magma-titulos-notas(1.5em);       
        }

        .imagen-imagen {
            img {
                max-width: 100%;
            }
        }

        .texto-imagen {
            margin: 20px 0;

            @include desktop {
                margin: 0 0 20px 0px;
            }
        }
    }
}

footer {
    width: 100%;
    position: relative;

    .footer {
        @include fuente-magma-titulos(1em);

        text-transform: uppercase;
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        bottom: 0;
        position: relative;
        z-index: 1;

        @include desktop {
            position: absolute;
            justify-content: space-evenly;
        }


        @include tablet {
            position: absolute;
            justify-content: space-evenly;
        }


        .logos {
            max-width: 250px;
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;
            justify-content: center;
            margin-bottom: 2%;
            align-self: flex-end;
            text-align: center;

            .col {
                justify-content: center;
                margin: 0;
                text-transform: lowercase;
                width: 100%;

                a {
                    width: 100%;
                    display: inline-block;
                }
            }

            .logo1 {
                width: 100%;
                max-width: 200px;
            }

            .logo2 {
                width: 37%;
                max-width: 90px;
            }

            svg {
                fill: cssvar(primary-icon);
                max-width: 100px;

                @include desktop {
                    max-width: 200px;
                }


                @include tablet {
                    max-width: 150px;
                }
            }
        }

        .secciones {
            margin-bottom: 7%;

            ul {
                list-style: none;
                margin-bottom: 0;

                li {
                    line-height: 1em;
                    height: 1.5em;

                    .nav-link {
                        padding: .5rem 1rem;
                        color: cssvar(primary-cta);
                        background: none;
                        border: none;
                        text-align: center;
                        line-height: 1em;

                        @include desktop {
                            text-align: left;
                        }


                        @include tablet {
                            text-align: left;
                        }
                    }
                }

                @include desktop {
                    padding-left: 21%;
                }


                @include tablet {
                    padding-left: 21%;
                }
            }
        }

        .copyright {
            font-size: .8em;
            padding: 15px;
            margin-bottom: 7%;

            @include fuente-magma-textos(1em);

            line-height: 1em;
            color: cssvar(primary-cta);
            text-align: center;

            p {
                margin: 0;
                text-transform: none;
                margin-left: 10px;

                &:first-of-type {
                    margin-top: 15px;
                    font-weight: bold;
                }

                @include desktop {
                    padding-right: 25%;
                }


                &:hover {
                    color: cssvar(primary-cta-hover);
                }
            }

            @include desktop {
                text-align: right;
            }


            @include tablet {
                padding-right: 8%;
                text-align: right;
                padding-left: 0;

                p {
                    font-size: .8em;
                }
            }
        }

        .redes {
            margin-left: auto;

            a {
                padding: 0;
                display: inline-flex;
                padding-left: 7px;
                color: cssvar(primary-cta);
                font-size: .9em;

                &:hover {
                    color: cssvar(primary-cta-hover);
                }

                i {
                    font-size: 1.5em;
                }
            }

            @include desktop {
                padding-right: 25%;
            }
        }

        .secciones, .copyright, .logos {
            max-width: 100%;

            &.logos {
                margin-bottom: 4%;
            }

            @include desktop {
                margin-bottom: 7.6%;
            }


            @include tablet {
                max-width: 33%;
                margin-bottom: 7%;

                &.logos {
                    svg {
                        max-width: 50%;
                    }
                }
            }
        }
    }

    .fondo {
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: cssvar(primary-icon);
        @include mask('./assets/decorativo/footer_entero.svg');

        img {
            visibility: hidden;
        }

        @include desktop {
            position: relative;
            margin-top: 30px;
        }


        @include tablet {
            position: relative;
        }
    }

    .footer-top {
        overflow: hidden;
        height: 150px;
        background-color: cssvar(primary-icon);
        @include mask('./assets/decorativo/pie_pttrn.svg');
        mask-size: cover;

        img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            visibility: hidden;
        }

        @include tablet {
            height: 200px;
            margin-bottom: 50px;
        }


        @include desktop {
            height: auto;
        }
    }

    &.mini {
        background: cssvar(primary-cta);
        z-index: 0;

        .footer {
            position: relative;
            align-items: baseline;
            padding: 20px 10px 10px;
            justify-content: space-between;

            .logos {
                max-width: inherit;
                display: block;
                text-align: center;
                margin-bottom: 2%;

                @include desktop {
                    display: flex;
                    text-align: right;
                }


                .logo-col {
                    display: inline-flex;
                    min-width: 50px;

                    .logo1 {
                        text-align: right;
                        margin: 0 auto;
                        cursor: pointer;

                        svg {
                            max-width: 200px;
                            fill: cssvar(main-background);

                            &:hover {
                                fill: cssvar(primary-cta-hover);
                            }
                        }
                    }
                }

                h3 {
                    margin: 0;
                    font-size: 1.3em;
                    line-height: 1em;
                    color: cssvar(main-background);
                    text-align: center;

                    @include desktop {
                        text-align: left;
                    }
                }

                a {
                    text-transform: lowercase;
                    margin: 0;
                    padding: 0;
                    font-size: 1.3em;
                    text-align: center;
                    display: inherit;
                    color: cssvar(main-background);

                    &:hover {
                        color: cssvar(primary-cta-hover);
                    }

                    @include desktop {
                        text-align: left;
                    }
                }

                .copyright {
                    padding: 0;
                    margin: 0;
                    margin-top: 10px;
                    text-align: center;
                    color: cssvar(main-background);

                    @include desktop {
                        text-align: left;
                    }


                    @include tablet {
                        max-width: 100%;
                    }


                    p {
                        margin: 0;

                        &:hover {
                            color: cssvar(primary-cta-hover);
                        }
                    }
                }
            }

            .secciones {
                margin: 0;
                color: cssvar(primary-text);
                text-align: center;
                padding-top: 30px;
                max-width: inherit;

                @include desktop {
                    text-align: right;
                }


                a {
                    color: cssvar(primary-cta-active);

                    &:hover {
                        color: cssvar(main-background);
                    }
                }

                ul {
                    flex-direction: column;
                    display: flex;
                    padding: 0;
                    justify-content: center;

                    @include desktop {
                        justify-content: flex-end;
                        flex-direction: row;
                    }


                    a.nav-link {
                        padding: 4px;
                        font-weight: normal;
                        font-size: .9em;
                        color: cssvar(primary-cta-active);

                        &:hover {
                            color: cssvar(main-background);
                        }

                        @include tablet {
                            text-align: center;
                        }
                    }
                }
            }

            .redes {
                padding-right: 0;
            }
        }
    }
}

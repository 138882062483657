.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: cssvar(primary-text);
    text-align: center;
    white-space: nowrap;
    background-color: cssvar(main-background);
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.list-group-item.active {
    color: cssvar(primary-text);
    background-color: cssvar(main-background);
    border-color: cssvar(main-background);
}

.list-group-item-primary {
    color: cssvar(main-background);
    background-color: cssvar(primary-text);
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: cssvar(main-background);
    background-color: cssvar(primary-text);
}

.list-group-item-primary.list-group-item-action.active {
    color: cssvar(primary-text);
    background-color: cssvar(main-background);
    border-color: cssvar(main-background);
}

.bg-primary {
    background-color: cssvar(main-background) !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: cssvar(main-background) !important;
}

.border-primary {
    border-color: cssvar(main-background) !important;
}

.text-primary {
    color: cssvar(main-background) !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: cssvar(main-background) !important;
}

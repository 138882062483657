.nosotros {
    padding-bottom: 30px;

    p {
        text-transform: none;
        margin-bottom: 10px;
    }

    p:not(.markdown) {
        display: inline-block;
    }

    b {
        display: inline;
    }

    button.btn:not(:disabled):not(.disabled) {
        margin-top: 30px;
    }
}

.nosotros-nombres {
  padding: 20px;

  @include desktop {
      padding: 20px #{$margenes + 30px};
  }


  h4 {
      text-transform: uppercase;
      font-size: 1em;
      padding-bottom: 0;
      margin-bottom: 0;
  }

  .btn-link {
      font-size: 1em;
      padding: 0;
      color: cssvar(primary-text);
      font-weight: bold;
      margin-bottom: 10px;
      
      &:hover{
        text-decoration: underline;
      }
  }

  .nombre-nosotros {
      text-align: center;

      h4 {
          text-align: center;
      }

      margin-bottom: 20px;
  }

  .inactive {
      opacity: .6;
  }
}
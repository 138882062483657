.tableWrapper { 
  margin-top: 60px;
  .row-header{
    color: cssvar(headlines);
    padding: 20px 0;
    font-size: 1.6em;
  }
  .row-item{
    border-bottom: 1px solid cssvar(primary-cta);
    padding: 10px;
    img{
      max-width: 100%;
    }
  }
  .editar{
    cursor: pointer;
    &:hover{
      color: cssvar(primary-cta-hover);
      text-decoration: underline;
    }
  }
  
}
